@import url('https://fonts.googleapis.com/css2?family=Redressed&display=swap');

.font1 {
  font-family: 'Redressed', cursive;
}

#links {
  position: absolute;
  width: 100%;
  background-color: #4e4c67;
  z-index: -2;
}

#links a:hover {
  color: #985f6f;
}

#back {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

#back {
  display: table;
  width: 100%;
  height: 100vh;
  /* background-color: #171717;
  color: #000; */
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  /* overflow: hidden; */
}

.lines {
  color: #dcd6f7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.line {
  color: #dcd6f7;
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #4e4c67;
  overflow: hidden;
}
.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, white, #dcd6f7 75%, #985f6f);
  -webkit-animation: drop 7s 0s infinite;
  animation: drop 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1) {
  margin-left: -25%;
}
.line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.line:nth-child(3) {
  margin-left: 25%;
}
.line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

@-webkit-keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

section {
  height: 100vh;
  width: 100%;

  /* backdrop-filter: blur(8px); */
}

a,
a:link,
a:visited {
  color: #dcd6f7;
}
a:hover {
  color: #4e4c67;
}

#back {
  background-color: #4e4c67;

  /* background: no-repeat center center fixed;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

#center {
  width: 100%;
}

body {
  height: 100vh;
}

.animate__animated.animate__fadeInDown {
  --animate-duration: 2s;
  color: #985f6f;
}
.animate__animated.animate__fadeInLeft {
  --animate-duration: 2.5s;

  /* --animate-delay: 6s; */
  display: inline-block;
  margin-right: 8px;
  margin-top: -20px;
  color: #985f6f;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 3s;
  /* --animate-delay: 3s; */
  /* --animate-delay: 5.9s; */
  display: inline-block;
  margin-top: -20px;
  color: #a6b1e1;
}
