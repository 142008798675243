#parallel {
  margin: 0 0 0 15px;
  -webkit-transform: skew(-5deg);
  -moz-transform: skew(-5deg);
  -o-transform: skew(-5deg);
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 2px 2px #d3d3d3;
}

#stack {
  background-color: #dcd6f7;

  margin-top: -20px;
}

#icon3 {
  margin-bottom: 40px;
}

#icon1,
#icon2 {
  margin-bottom: 80px;
}

#icon1,
#icon2,
#icon3 {
  margin-top: 40px;
}

.card {
  color: #985f6f;
  opacity: 0.9;
}

html {
  scroll-behavior: smooth;
}

#strive {
  background-color: #a6b1e1;
}

#aboutBackground {
  /* z-index: ; */
  height: 100vh;
  width: 100%;
  background-color: #dcd6f7;
  /* -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}
