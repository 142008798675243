#mainDiv {
  height: 100vh;
  width: 100%;
  top: -13px;
}
article {
  opacity: 0.9;
  top: -10px;
  border-radius: 50px;
  border-left: 5px solid black;
}

img#image {
  border-radius: 30px;
}

article:hover {
  opacity: 1;
}

#singleBack {
  background: no-repeat center center fixed;

  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5px;
}
