@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
#parallel {
  margin: 0 0 0 15px;
  -webkit-transform: skew(-5deg);
  -moz-transform: skew(-5deg);
  -o-transform: skew(-5deg);
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 2px 2px #d3d3d3;
}

#stack {
  background-color: #dcd6f7;

  margin-top: -20px;
}

#icon3 {
  margin-bottom: 40px;
}

#icon1,
#icon2 {
  margin-bottom: 80px;
}

#icon1,
#icon2,
#icon3 {
  margin-top: 40px;
}

.card {
  color: #985f6f;
  opacity: 0.9;
}

html {
  scroll-behavior: smooth;
}

#strive {
  background-color: #a6b1e1;
}

#aboutBackground {
  /* z-index: ; */
  height: 100vh;
  width: 100%;
  background-color: #dcd6f7;
  /* -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

#mainDiv {
  height: 100vh;
  width: 100%;
  top: -13px;
}
article {
  opacity: 0.9;
  top: -10px;
  border-radius: 50px;
  border-left: 5px solid black;
}

img#image {
  border-radius: 30px;
}

article:hover {
  opacity: 1;
}

#singleBack {
  background: no-repeat center center fixed;
  background-size: cover;
  padding-top: 5px;
}

#blogBackground {
  height: auto;
  width: 100%;
  background-color: #4e4c67;

  /* -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

/* #backgroundColor2 {
  height: 100%;
  width: 100%;
  background-color: #4e4c67;
} */

* {
  margin: 0;
  padding: 0;
}

/* html,
body {
  background-color: inherit;
} */

.containers {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containers .box {
  width: 250px;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.containers .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
.containers .box .title .block {
  width: 0%;
  height: inherit;
  background: #985f6f;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.containers .box .title h1 {
  font-family: 'Poppins';
  color: #fff;
  font-size: 32px;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
}
.containers .box .title h1 span {
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background: #ffb510;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}
.containers .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -10px;
}
.containers .box .role .block {
  width: 0%;
  height: inherit;
  background: #e91e63;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
.containers .box .role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  font-weight: 400;
  font-family: 'Lato';
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #417844;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #985f6f;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #a6b1e1;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
/* footer {
  width: 350px;
  height: 80px;
  background: #ffb510;
  position: absolute;
  right: 0;
  bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: top 0.8s forwards;
  animation-delay: 4s;
}
footer span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #232323;
  font-family: 'Poppins';
}
footer span i {
  margin-right: 25px;
  font-size: 22px;
  color: #232323;
  animation: icon 2s forwards;
  animation-delay: 4s;
  opacity: 0;
} */

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

.font1 {
  font-family: 'Redressed', cursive;
}

#links {
  position: absolute;
  width: 100%;
  background-color: #4e4c67;
  z-index: -2;
}

#links a:hover {
  color: #985f6f;
}

#back {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

#back {
  display: table;
  width: 100%;
  height: 100vh;
  /* background-color: #171717;
  color: #000; */
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  /* overflow: hidden; */
}

.lines {
  color: #dcd6f7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.line {
  color: #dcd6f7;
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #4e4c67;
  overflow: hidden;
}
.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, white, #dcd6f7 75%, #985f6f);
  animation: drop 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1) {
  margin-left: -25%;
}
.line:nth-child(1)::after {
  animation-delay: 2s;
}
.line:nth-child(3) {
  margin-left: 25%;
}
.line:nth-child(3)::after {
  animation-delay: 2.5s;
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

section {
  height: 100vh;
  width: 100%;

  /* backdrop-filter: blur(8px); */
}

a,
a:link,
a:visited {
  color: #dcd6f7;
}
a:hover {
  color: #4e4c67;
}

#back {
  background-color: #4e4c67;

  /* background: no-repeat center center fixed;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

#center {
  width: 100%;
}

body {
  height: 100vh;
}

.animate__animated.animate__fadeInDown {
  --animate-duration: 2s;
  color: #985f6f;
}
.animate__animated.animate__fadeInLeft {
  --animate-duration: 2.5s;

  /* --animate-delay: 6s; */
  display: inline-block;
  margin-right: 8px;
  margin-top: -20px;
  color: #985f6f;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 3s;
  /* --animate-delay: 3s; */
  /* --animate-delay: 5.9s; */
  display: inline-block;
  margin-top: -20px;
  color: #a6b1e1;
}

